<template>
    <el-row style="width: 100%;">
        <el-col :xs="24" :md="24" class="lf_el-col">
			<div class="lf_score_box">
				
				<yard :match="match" :matchhole="matchhole"></yard>
				<div class="lf_score_bgzg_box">
					<div id="lf_score_TDDF_box_tit" :class="searchBarFixed == true ? 'isFixed lf_score_TDDF_box_tit' :'lf_score_TDDF_box_tit'">
						<div class="lf_score_tddf_pos">{{langs.text_ranking1}}</div>
						<div class="lf_score_pos_team">{{langs.text_team}}</div>
						<div class="lf_score_tddf_zf">{{langs.TotalPoints}}</div>
						<div class="lf_score_tddf_Rd" v-for="(i,index) in roundarr">
							R{{i}}
						</div>
						<div class="lf_score_tddf_cj">{{langs.Score}}</div>
						<div class="lf_score_tddf_zg">{{langs.text_Totals}}</div>
					</div>
					<template  v-for="(team,index) in teams">
						<div class="lf_score_TDDF_box_tit_con">
							<div class="lf_score_tddf_pos">
								{{team.teamsummary?team.teamsummary.rank_code:''}}{{team.teamsummary?team.teamsummary.rank:''}}
								<div v-if="rd_id>1" :class="team.playerSummary!=undefined?(team.playerSummary.change>0?'lf_pos_change_lv lf_score_pos_c':'lf_pos_change_red lf_score_pos_c'):'lf_score_pos_c'" style="position: absolute;right: 10px;">
									<img v-if="team.playerSummary!=undefined&&team.playerSummary.change<0" style="width: 5px;height: 12px;" src="../../assets/images/RED.png" alt="">
									<img v-if="team.playerSummary!=undefined&&team.playerSummary.change>0" style="width: 5px;height: 12px;" src="../../assets/images/GREEN.png" alt="">
									<img v-if="team.playerSummary!=undefined&&team.playerSummary.change!==''&&team.playerSummary.change==0" style="width: 14px;height: 12px;" src="../../assets/images/dd.png" alt="">
									{{ team.playerSummary!=undefined?(team.playerSummary.change<0?Math.abs(team.playerSummary.change):(team.playerSummary.change==0?"":team.playerSummary.change)):"" }}
								</div>
							</div>
							<div class="lf_score_pos_team">{{team.team_name}}</div>
							<div class="lf_score_tddf_zf">{{team.teamsummary!=undefined?team.teamsummary.totalpoint:"-"}}</div>
							<div class="lf_score_tddf_Rd" v-for="(rd,index) in roundarr">
								<template v-if="team.teamsummaryround&&team.teamsummaryround[rd]&&team.teamsummaryround[rd].score>0">
									{{team.teamsummaryround!=undefined&&team.teamsummaryround[rd]!=undefined?team.teamsummaryround[rd].point:""}}
								</template>
							</div>
							<div :class="team.teamsummary?(team.teamsummary.totalto_par<=-1?'lf_score_other_2':(team.teamsummary.totalto_par==0?'lf_score_other_3':'lf_score_other_4')):''" class="lf_score_tddf_cj">{{team.teamsummary!=undefined&&team.teamsummary.totalscore>0?(team.teamsummary.totalto_par>0?('+'+team.teamsummary.totalto_par):(team.teamsummary.totalto_par==0?'E':team.teamsummary.totalto_par)):""}}</div>
							<div class="lf_score_tddf_zg">{{team.teamsummary!=undefined?team.teamsummary.totalscore:""}}</div>
						</div>
						<div id="lf_score_bgzg_box_tit" class="lf_score_bgzg_box_tit">
							<div class="lf_score_pos"></div>
							
							<div class="lf_score_pos_player">
								<text>{{langs.TDDF}}</text>
								<text>(<template v-if="match_set.notes=='PAR'">{{langs.qgh}}</template><template v-if="match_set.notes=='HOLE'">{{langs.zdqn}}</template>{{match_set.num}})</text>
							</div>
							<div class="lf_score_wc">{{langs.text_Thru}}</div>
							<div class="lf_score_9" :class="index%3==0&&index!=0?'lf_sbjj_pdl':''" v-for="(i,index) in [1,2,3,4,5,6,7,8,9]">
								<div>{{matchhole.hole[i-1].ho_id}}</div>
								<div>{{matchhole.hole[i-1]!=undefined?matchhole.hole[i-1].par:''}}</div>
							</div>
							<div class="lf_score_out_in">
								<div>OUT</div>
								<div>{{ outpar }}</div>
							</div>
							<div class="lf_score_9" :class="index%3==0&&index!=0?'lf_sbjj_pdl':''" v-for="(i,index) in [10,11,12,13,14,15,16,17,18]">
								<div>{{matchhole.hole[i-1].ho_id}}</div>
								<div>{{matchhole.hole[i-1]!=undefined?matchhole.hole[i-1].par:''}}</div>
							</div>
							<div class="lf_score_out_in">
								<div>IN</div>
								<div>{{ inpar }}</div>
							</div>
							<div class="lf_score_rd" v-if="parflag==0">
								<div>R{{ rd_id }}</div>
								<div>{{matchhole.par}}</div>
							</div>
							<div class="lf_score_zg_zg" v-if="parflag==1">{{langs.TotalPoints}}</div>
						</div>
						<div class="lf_score_bgzg_box_con" v-for="(item,index) in team.teamplayer" :style="parflag==0?'height:90px':'height:60px'">
							<div class="lf_score_pos"></div>
							
							<div class="lf_score_pos_player">
								<img v-if="match_set.mt_country_show_type==1" class="lf_country_img" :src="'http://overview.wifigolf.com/images/flags/changurl.php?mt_id='+mt_id+'&content=64/'+ (item.player!=undefined&&item.player.pl_country&&item.player.pl_country!='*'?item.player.pl_country:'country')+'.png'" alt="">
								<span v-if="match_set.mt_country_show_type==2" class="lf_country_text">{{item.player!=undefined&&item.player.pl_country?item.player.pl_country:''}}</span>
								<div class="lf_country_player_text" @click="openplayerscoredetail(item.pl_id)">{{ item.player!=undefined?item.player.pl_cn_name:"" }}{{ item.groupDetail!=undefined&&item.groupDetail.gp_start_hole>9&&item.groupDetail.gp_start_hole<18?'*':'' }}{{ match_set.mt_amateur=='(A)'&&item.player.pl_type=='AMA'?'(A)':'' }}</div>
								<div v-if="match_set.tee_show_type==1" class="lf_country_tee_text">{{ item.matchPlayer!=undefined?item.matchPlayer.mp_teename.split("")[0]:"" }}</div>
								<div v-if="((match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==1))||((match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==0))" class="lf_country_sex_box">
									<img v-if="(match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==1)" class="lf_country_sex_img" src="../../assets/images/u7.png" alt="">
									<img v-if="(match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==0)" class="lf_country_sex_img" src="../../assets/images/u9.png" alt="">
								</div>
								
								<img v-if="item.matchPlayer!=undefined&&item.matchPlayer.mp_logo!=''&&item.matchPlayer.mp_logo!=null" class="lf_country_zzs_img" :src="'http://overview.wifigolf.com/club/adm/images/matches/'+mt_id+'/'+item.matchPlayer.mp_logo" alt="">
								<template v-if="match_set.xueyuan_show==1">
									<div class="lf_country_club_text">{{ item.player?item.player.xueyuan:"" }}</div>
								</template>
							</div>
							<div :class="item.playerSummary!=undefined&&item.playerSummary.sts=='C'?'lf_score_wc_F':''" class="lf_score_wc">{{item.playerSummary!=undefined&&item.playerSummary.thru!=0?(item.playerSummary.thru==18?"F":item.playerSummary.thru):""}}</div>
							<!-- <div :class="item.playerSummary!=undefined&&item.playerSummary.sts=='C'?'lf_score_wc_F':''" class="lf_score_wc">{{item.playerSummary!=undefined?(item.playerSummary.thru==18?"F":item.playerSummary.thru):""}}</div> -->
							<div class="lf_score_9" :class="index%3==0&&index!=0?'lf_sbjj_pdl':''+' '+ parflag==1||item.playerScore==undefined||item.playerScore[i-1]==undefined||item.playerScore[i-1].score==''?'':(item.playerScore[i-1].to_par<=-2?lf_score_1:(item.playerScore[i-1].to_par==-1?lf_score_2:(item.playerScore[i-1].to_par==0?lf_score_3:(item.playerScore[i-1].to_par==1?lf_score_4:lf_score_5))))" v-for="(i,index) in [1,2,3,4,5,6,7,8,9]">

								<text class="lf_score_9_1" :class="parflag==1||item.playerScore==undefined||item.playerScore[i-1]==undefined||item.playerScore[i-1].score==''?'':(item.playerScore[i-1].to_par<=-2?lf_score_1:(item.playerScore[i-1].to_par==-1?lf_score_2:(item.playerScore[i-1].to_par==0?lf_score_3:(item.playerScore[i-1].to_par==1?lf_score_4:lf_score_5))))" v-if="parflag==0">


									{{item.playerScore!=undefined&&item.playerScore[i-1]!=undefined?(item.playerScore[i-1].score==31?"/":item.playerScore[i-1].score):""}}

								</text>
								<text class="lf_score_9_2">
									{{item.playerScore&&item.playerScore[i-1]?item.playerScore[i-1].point:""}}
								</text>
								<div class="lf_pos_line_bottom" v-if="team&&team.teamscore&&team.teamscore[i]&&team.teamscore[i].plids.indexOf(item.pl_id)!=-1"></div>
							</div>
							<div class="lf_score_out_in">
								<text class="lf_score_out_in_1" v-if="parflag==0">
								{{item.playerSummary!=undefined?item.playerSummary.out:""}}
								</text>
								<text class="lf_score_out_in_2">
									{{ item.playerSummary?item.playerSummary["outpoint"]:"" }}
								</text>
							</div>
							<div class="lf_score_9"  :class="index%3==0&&index!=0?'lf_sbjj_pdl':''+' '+ parflag==1||item.playerScore==undefined||item.playerScore[i-1]==undefined||item.playerScore[i-1].score==''?'':(item.playerScore[i-1].to_par<=-2?lf_score_1:(item.playerScore[i-1].to_par==-1?lf_score_2:(item.playerScore[i-1].to_par==0?lf_score_3:(item.playerScore[i-1].to_par==1?lf_score_4:lf_score_5))))" v-for="(i,index) in [10,11,12,13,14,15,16,17,18]">

								<text class="lf_score_9_1" :class="parflag==1||item.playerScore==undefined||item.playerScore[i-1]==undefined||item.playerScore[i-1].score==''?'':(item.playerScore[i-1].to_par<=-2?lf_score_1:(item.playerScore[i-1].to_par==-1?lf_score_2:(item.playerScore[i-1].to_par==0?lf_score_3:(item.playerScore[i-1].to_par==1?lf_score_4:lf_score_5))))" v-if="parflag==0">

									{{item.playerScore!=undefined&&item.playerScore[i-1]!=undefined?(item.playerScore[i-1].score==31?"/":item.playerScore[i-1].score):""}}

								</text>
								<text class="lf_score_9_2">
									{{item.playerScore&&item.playerScore[i-1]?item.playerScore[i-1].point:""}}
								</text>
								<div class="lf_pos_line_bottom" v-if="team&&team.teamscore&&team.teamscore[i]&&team.teamscore[i].plids.indexOf(item.pl_id)!=-1"></div>
							</div>
							<div class="lf_score_out_in">
								<text class="lf_score_out_in_1" v-if="parflag==0">
								{{item.playerSummary!=undefined?item.playerSummary.in:""}}
								</text>
								<text class="lf_score_out_in_2">
									{{ item.playerSummary?item.playerSummary["inpoint"]:"" }}
								</text>
							</div>
							<!-- <template v-if="0">
								<div v-if="item.playerSummary!=undefined&&item.playerSummary.thru>=18" class="lf_score_rd" :class="item.playerSummary==undefined?'':(item.playerSummary.to_par<0?'lf_score_other_2':'lf_score_other_3')">
									{{item.playerSummary!=undefined?item.playerSummary.score:""}}
								</div>
								<div v-else class="lf_score_rd" :class="item.playerSummary==undefined?'':(item.playerSummary.to_par<0?'lf_score_other_2':(item.playerSummary.to_par==0?'lf_score_other_3':'lf_score_other_4'))">
									{{item.playerSummary!=undefined&&item.playerSummary.thru!=0?(item.playerSummary.to_par>0?("+"+item.playerSummary.to_par):(item.playerSummary.to_par==0?'E':item.playerSummary.to_par)):""}}
								</div>
							</template> -->
							<div class="lf_score_zg_zg" v-if="parflag==0">
								<text class="lf_score_zg_zg_1">
								{{item.playerSummary!=undefined&&(item.playerSummary.score>0||item.playerSummary.score=="/")?item.playerSummary.score:""}}
								</text>
								<text class="lf_score_zg_zg_2">
									{{ item.playerSummary?item.playerSummary["jspoint"]:"" }}
								</text>
							</div>
							<div class="lf_score_zg_zg" v-if="parflag==1">
								<text class="lf_score_zg_zg_1" style="font-weight: bold;">
								{{item.playerSummary!=undefined?item.playerSummary.jspoint:""}}
								</text>
							</div>
						</div>
					</template>
				</div>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	import axiosx from '../../axios/postx';
	import axios from '../../axios/request';
	import yard from '../yard.vue';
	import addHole from '../addHole.vue';
	import { getSystemLang, getLangAll } from "../../lang";
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
				value: '',
				outpar:'',
				inpar:'',
				mt_id:'',
				showjinji:-1,
				lf_score_1:"lf_score_1",
				lf_score_2:"lf_score_2",
				lf_score_3:"lf_score_3",
				lf_score_4:"lf_score_4",
				lf_score_5:"lf_score_5",
				searchBarFixed:false,
				HtmloffsetTop:0,
				roundarr: []
		    }
		},
        name: 'TDDF18_42',
        props: {
            msg: String,
            flag:String,
			cd:String  ,//菜单
			undergoing:String,
			list:Object,
			playoff:Object,
			match:Object,
			match_set:Object,
			matchhole:Object,
			rd_id:String,
			teams: Object,
			parflag: String
        },
		components: {
		    yard,
			addHole
		},
		created() {
			console.log()
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			// this.rd_id = this.match_set.rd_id;
			this.mt_id = this.match_set.mt_id;
			for(var k in this.match_set.mt_show_style){
				if(k == 0){
					this.lf_score_1 = this.match_set.mt_show_style[k];
				}else if(k == 1){
					this.lf_score_2 = this.match_set.mt_show_style[k];
				}else if(k == 2){
					this.lf_score_3 = this.match_set.mt_show_style[k];
				}else if(k == 3){
					this.lf_score_4 = this.match_set.mt_show_style[k];
				}else if(k == 4){
					this.lf_score_5 = this.match_set.mt_show_style[k];
				}
			}
			this.changepar();
			for(var k in this.list){
				if(this.list[k].matchPlayer.mp_sts=="MC"){
					this.showjinji = k;
					break;
				}
			}
			var roundarr = [];
			for(var i=1;i<=this.match.mt_round_num;i++){
				roundarr.push(i)
			}
			this.roundarr = roundarr;
		},
		watch:{
			rd_id:function(nval,oval){
				this.rd_id = nval;
			},
			list:function(nval,oval){
				for(var k in nval){
					if(nval[k].matchPlayer.mp_sts=="MC"){
						this.showjinji = k;
						break;
					}
				}
				this.$nextTick(()=>{
					this.$parent.setscorecolor();
				});
			},
			playoff:function(nval,oval){
			},
			teams:function(nval,oval){
				this.changeHtmloffsetTop();
			},
			parflag:function(nval,oval){
				this.$nextTick(()=>{
					this.$parent.setscorecolor();
				});
			}
		},
		mounted(){
			this.$parent.setscorecolor();
			window.addEventListener('scroll', this.handleScroll);
			this.changeHtmloffsetTop();
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll)
		},
		methods:{
			changeHtmloffsetTop(){
				var div = document.getElementById('lf_score_bgzg_box_tit');
				if(div!=undefined){
					this.HtmloffsetTop = div.getBoundingClientRect().top;
				}
			},
			handleScroll () {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				//console.log(scrollTop);
				console.log(this.HtmloffsetTop);
				if (scrollTop > this.HtmloffsetTop) {
					this.searchBarFixed = true
				} else {
					this.searchBarFixed = false
				}
			},
			changepar(){
				var outpar = 0;
				var inpar = 0;
				for(var k in this.matchhole.hole){
					if(this.matchhole.hole[k].ho_id<=9){
						outpar+=this.matchhole.hole[k].par;
					}else if(this.matchhole.hole[k].ho_id<=18&&this.matchhole.hole[k].ho_id>9){
						inpar+=this.matchhole.hole[k].par;
					}
				}
				this.outpar = outpar;
				this.inpar = inpar;
			},
			openplayerscoredetail(pl_id){
				var mt_id = this.mt_id;
				var clubid = this.match.club_id;
				var tourid = this.match.tour_id;
				// var url = "http://overview.wifigolf.com/customer/playerScore.php?lang=1&ClubID="+clubid+"&TourID="+tourid+"&MatchID="+mt_id+"&s=match&Pl_id="+pl_id;
				var url = "score?lang=cn&ClubID="+clubid+"&TourID="+tourid+"&MatchID="+mt_id+"&s=match&Pl_id="+pl_id;
				window.open(url);
			}
		}
    }
</script>
<style>
	html {
	-webkit-filter: grayscale(100%);
	filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
	}
	.lf_daochu_type_box .el-input,.el-select{
		height: 100%;
		border-radius: 4px;
		font-size: 22px;
		border: unset;
	}
	.lf_daochu_type_box .select-trigger{
		height: 100%;
	}
	.lf_el-col .lf_daochu_type_box .el-input.is-focus,.lf_el-col .lf_daochu_type_box .el-input.is-focus .el-input__wrapper,.lf_el-col .lf_daochu_type_box .el-input__wrapper{
		box-shadow: unset!important;
		background-color: unset;
	}
	.lf_el-col .el-input__inner{
		font-size: 16px;
	}
	
	.lf_zgjg_btn_box_right .el-input,.el-select{
		height: 100%;
		border-radius: 4px;
		font-size: 22px;
		border: unset;
	}
	.lf_zgjg_btn_box_right .select-trigger{
		height: 100%;
	}
	.lf_el-col .lf_zgjg_btn_box_right .el-input.is-focus,.lf_el-col .lf_zgjg_btn_box_right .el-input.is-focus .el-input__wrapper,.lf_el-col .lf_zgjg_btn_box_right .el-input__wrapper{
		box-shadow: unset!important;
		background-color: unset;
	}
	.lf_el-col .el-input__inner{
		font-size: 16px;
	}
	
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .lf_score_box{
		max-width: 1280px;
		margin: 0 auto;
	}
	.lf_tips_box_A{
		height: 50px;
		display: flex;
		align-items: center;
		font-size: 18px;
		color: #333;
	}
	.lf_tips_box_A div{margin-right: 20px;}
	.lf_tips_box_A div span{
		color: #027512;
	}
	.lf_zgjg_btn_box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1280px;
		height: 40px;
		background: inherit;
		background-color: rgba(240, 243, 246, 1);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
}
	.lf_zgjg_btn_box_left{
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.lf_zgjg_btn_box_left div{
		max-width: 120px;
		height: 40px;
		margin-left: 20px;
		font-size: 22px;
		color: #333;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.lf_zgjg_btn_box_left div img{
		display: none;
	}
	.lf_zgjg_btn_box_left .lf_zgjg_btn_box_left_select{
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 22px;
		color: #0066CC;
		text-align: center;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_zgjg_btn_box_left .lf_zgjg_btn_box_left_select img{
		position: absolute;
		bottom: 0;
		width: 16px;
		height: 8px;
		display: block;
	}
	
	.lf_score_bgzg_box{
		width: 1280px;
		margin: 20px auto;
	}
	.lf_score_TDDF_box_tit{
		display: flex;
		align-items: center;
		background-color: #202242;
		height: 50px;
		color: #fff;
		
	}
	.lf_score_TDDF_box_tit div{
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Arial Normal', 'Arial';
		    font-weight: 400;
		    font-style: normal;
			font-size: 20px;
	}
	.lf_score_tddf_pos{
		width: 100px;
		flex-shrink: 0;
	}
	.lf_score_pos_team{
		width: 380px;
		flex-shrink: 0;
	}
	.lf_score_tddf_zf{
		width: 180px;
		flex-shrink: 0;
	}
	.lf_score_tddf_Rd{
		width: 120px;
		flex-shrink: 1;
	}
	.lf_score_tddf_cj{
		width: 160px;
		flex-shrink: 0;
	}
	.lf_score_tddf_zg{
		width: 220px;
		flex-grow: 0;
	}
	.lf_score_TDDF_box_tit_con{
		background-color: #fff;
		color: #333;
		display: flex;
		align-items: center;
		height: 60px;
		color: #333;
		border-top: 1px solid #dbdbdb;
	}
	.lf_score_TDDF_box_tit_con div{
		font-weight: bold;
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_TDDF_box_tit_con .lf_score_tddf_pos{
		color: #0066CC;
		position: relative;
	}
	.lf_score_TDDF_box_tit_con .lf_score_pos_team{
		color: #0066CC;
		font-size: 22px;
	}
	
	.lf_score_bgzg_box_tit{
		display: flex;
		align-items: center;
		background-color: #F0F3F6;
		height: 60px;
		border-bottom: none;
	}
	.lf_score_bgzg_box_tit div{
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		color: #333;
	}
	.lf_score_bgzg_box_tit .lf_score_pos_player{
		flex-direction: column;
		font-size: 20px;
	}
	.lf_score_bgzg_box_tit .lf_score_pos_player text:nth-last-of-type(1){
		font-size: 16px;
	}
	.lf_score_bgzg_box_con{
		display: flex;
		align-items: center;
		height: 60px;
		background-color: #fff;
		border-bottom: none;
		
	}
	.lf_score_bgzg_box_con div{
		display: flex;
		align-items: center;
		font-size: 24px;
	}
	.lf_score_bgzg_box_con:nth-of-type(2n+1){
		background-color: #fafafa;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player{
		position: relative;
		padding-left: 10px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_club_text{
		position: absolute;
		bottom: 0;
		height: 22px;
		font-family: '微软雅黑 Light', '微软雅黑 Regular', '微软雅黑';
		font-weight: 250;
		font-style: normal;
		font-size: 16px;
		color: #55565B;
		line-height: 22px;
		width: 100%;
		overflow: hidden;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_img{
		width: 30px;
		height: 20px;
		margin-right: 8px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_player_text{
		    font-family: '微软雅黑';
		    font-weight: 400;
		    font-style: normal;
		    font-size: 22px;
		    color: #000000;
		    text-align: left;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_text{
		    font-family: 'Arial Narrow', 'Arial';
		        font-weight: 400;
		        font-style: normal;
		        font-size: 20px;
		        color: #666666;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_sex_box{
		width: 24px;
		height: 24px;
		background: inherit;
		background-color: rgba(238, 238, 238, 1);
		border: none;
		border-radius: 24px;
		margin: 0 2px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_sex_img{
		width: 16px;
		height: 16px;
		margin: 0 2px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_zzs_img{
		width: 80px;
		height: 60px;
		margin: 0 2px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_tee_text{
		width: 24px;
		    height: 24px;
		    background: inherit;
		    background-color: rgba(238, 238, 238, 1);
		    border: none;
		    border-radius: 24px;
		    -moz-box-shadow: none;
		    -webkit-box-shadow: none;
		    box-shadow: none;
		    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		    font-weight: 700;
		    font-style: normal;
		    font-size: 14px;
			margin: 0 2px;
	}
	.lf_score_pos{
		width: 100px;
		height: 100%;
		background-color: #fff;
	}
	.lf_score_pos_c{
		width: 40px;
		height: 100%;
		display: flex;
		flex-direction: row;
		font-size: 18px;
	}
	.lf_pos_change_lv{
		color: #027512;
	}
	.lf_pos_change_red{
		color: #B72A47;
	}
	.lf_score_pos_player{
		width: 270px;
		height: 100%;
		flex-grow: 1;
	}
	.lf_score_wc{
		width: 70px;
		height: 100%;
		font-size: 24px;
		color: #000000;
		justify-content: center;
	}
	.lf_score_wc_F{
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 24px;
		color: #027512;
	}
	.lf_score_cj{
		width: 60px;
		height: 100%;
		font-weight: bold;
		justify-content: center;
	}
	.lf_score_9{
		width: 32px;
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
	}
	.lf_score_9 .lf_pos_line_bottom{
		width: 30px;
		height: 6px;
		background: inherit;
		background-color: rgba(0, 102, 204, 1);
		border: none;
		border-radius: 3px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		position: absolute;
		bottom: 1px;
		left: 1px;
	}
	.lf_score_9_1{
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		background-color: #fff;
	}
	.lf_score_9_2{
		height: 50px;
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 24px;
		color: #666B89;
		background-color: #fafafa;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_out_in_1{
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #F0F3F6;
	}
	.lf_score_out_in_2{
		height: 50px;
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 24px;
		color: #666B89;
		background-color: #F0F3F6;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_zg_zg_1{
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_zg_zg_2{
		height: 50px;
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 24px;
		color: #666B89;
		background-color: #95A6EC;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #fff;
		box-sizing: border-box;
	}
	.lf_score_9 div{
		width: 32px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_9.lf_sbjj_pdl{
		width: 38px;
	}
	.lf_score_9.lf_sbjj_pdl div{
		padding-left: 6px;
		width: 100%;
	}
	.lf_score_bgzg_box_tit .lf_score_9 div:nth-of-type(1){
		background-color: #0E1B4D;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_9 div:nth-of-type(2){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_out_in div:nth-of-type(1){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_out_in div:nth-of-type(2){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_rd div:nth-of-type(1){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_rd div:nth-of-type(2){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_zg_zg{
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_out_in{
		width: 50px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.lf_score_out_in div{
		width: 50px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_rd{
		width: 70px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.lf_score_rd div{
		width: 100%;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_zg_zg{
		width: 70px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.lf_score_pos_c img{
		width: 5px;
		height: 12px;
	}
	.lf_score_bgzg_box_con .lf_score_out_in{
		background-color: #F0F3F6;
	}
	.lf_score_bgzg_box_con .lf_score_zg_zg{
		background-color: #95A6EC;
		color: #fff;
	}
	
	
	
    @media screen and (max-width: 768px) {
        
    }
</style>
