import axios from "axios";
import qs from "qs";
import {
    getSystemLang
} from "../lang";

export default function($url,$param){

    let domain  = window.location.protocol+"//"+window.location.host;
    // axios.defaults.baseURL = '/test/api/index.php/';
    if(window.location.hostname=="localhost"){
        axios.defaults.baseURL = '/swd/';
    }else if(window.location.hostname=="pctest.wifigolf.com"){
        axios.defaults.baseURL = 'http://testapi.wifigolf.com/api/';
    }else{
        axios.defaults.baseURL = 'https://api.wifigolf.com/api/'; //正式
    }


    let lang = getSystemLang();
    if(lang == 'cn')
    {
        lang = 'zh-cn';
    }
    else if(lang == 'en')
    {
        lang = 'en-us';
    }
    else if(lang == 'tw')
    {
        lang = 'zh-tw';
    }
    else
    {
        lang = 'zh-cn';
    }

    // let tparam = [];
    // if($param instanceof Object)
    // {
    //     for(let key in $param)
    //     {
    //         tparam.push(key+'='+ encodeURIComponent($param[key]));
    //     }
    // }

    // if(tparam.length > 0)
    // {

    //     $url = 'core/'+lang+'/'+$url+'?'+tparam.join("&"); 
    // }
    // else
    // {
    //     $url = 'core/'+lang+'/'+$url;
    // }

    $url = lang+'/v1/'+$url;
    
    return axios.post($url,qs.stringify($param));
}